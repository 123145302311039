
        
        function animate(){
            
                    $('.oxy-animation').each(function() {

            var osElement = $(this),
                osAnimationClass = osElement.attr('data-os-animation'),
                osAnimationDelay = osElement.attr('data-os-animation-delay');

            osElement.css({
                '-webkit-animation-delay':  osAnimationDelay,
                '-moz-animation-delay':     osAnimationDelay,
                'animation-delay':          osAnimationDelay
            });

            new Waypoint({
                context: $('.mdl-layout__content')[0],
                element: osElement[0],
                handler: function() {
                    osElement.addClass('animated').addClass(osAnimationClass);
                },
                offset: '90%'
            });
        });
            
        }
        
        function whichAnimationEvent(){
  var t,
      el = document.createElement("fakeelement");

  var animations = {
    "animation"      : "animationend",
    "OAnimation"     : "oAnimationEnd",
    "MozAnimation"   : "animationend",
    "WebkitAnimation": "webkitAnimationEnd"
  }

  for (t in animations){
    if (el.style[t] !== undefined){
      return animations[t];
    }
  }
}

        var anim_cart_pending=false;
                function onCartAddAnimation(addButton) {
            var $addButton = $(addButton);
            var addProductClickAnimation = 'tada animated';
            var addProductToCartAnimation = 'rubberBand animated';

            // make sure button hasnt been already clicked and is currently animating
            if(!anim_cart_pending) {
                // set flag so we no button is doing some animating
                anim_cart_pending = true;
                // $(".oxy-button-add-product").prop('disabled', true);
                // animate the cart button when first clicked
                
                
                var animationEvent = whichAnimationEvent();
      $addButton.addClass(addProductClickAnimation).one(animationEvent,
              function(event) {
                    // now animation is finished remove animation classes
                    $addButton.removeClass(addProductClickAnimation);
                    // add pending class to the add product button
                    $addButton.addClass('oxy-button-add-product--pending');

                    var fromTop = $addButton.offset().top;
                    var fromLeft = $addButton.offset().left;
                    // create a clone of the button to animate
                    var clonedButton = $addButton.clone().offset({
                        top: fromTop,
                        left: fromLeft
                    }).addClass('oxy-button-add-product--clone').appendTo($('body'));
                    // grab the shopping cart button and animate to it
                    var $cartButton = $('.oxy-js-button-shopping-cart');

                    // // figure the destination point and calculate its distance from the starting point
                    var destTop = $cartButton.offset().top + ($cartButton.height() / 2) - clonedButton.height() / 2;
                    var destLeft = $cartButton.offset().left + ($cartButton.width() / 2) - clonedButton.width() / 2;
                    var distance = Math.floor(Math.sqrt(Math.pow(fromTop - destTop, 2) + Math.pow(fromLeft - destLeft, 2)));
                    var duration = 600 + Math.floor(distance / 3);

                    clonedButton.transition({
                        top: destTop,
                        left: destLeft,
                        easing: 'easeInOutQuart',
                        duration: duration,
                        complete: function() {
                            // remove cloned button
                            clonedButton.remove();
                            // reset flag to allow add product button to work again
                            anim_cart_pending = false;
                            //  $(".oxy-button-add-product").prop('disabled', false);
                            // animate the cart fab button
                            $cartButton.addClass(addProductToCartAnimation).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                                // increment cart count
                                $cartButton.removeClass(addProductToCartAnimation);
                            });
                            // remove pending class from add product button
                            $addButton.removeClass('oxy-button-add-product--pending').addClass('oxy-button-add-product--added');
                        }
                    });
                });
            }
        };
        
        
 

        
        
        function onSideCartClose(){
 toggleClass(document.getElementById('hc-product-cart'),'is-visible');
            toggleClass(document.getElementById('side-cart-obfuscator'),'is-visible')
        }
        
        function toggleClass(element, className){
    if (!element || !className){
        return;
    }
    
    var classString = element.className, nameIndex = classString.indexOf(className);
    if (nameIndex == -1) {
        classString += ' ' + className;
    }
    else {
        classString = classString.substr(0, nameIndex) + classString.substr(nameIndex+className.length);
    }
    element.className = classString;
}

(function($) {
    'use strict';
    // Loading Slabtext on window load to make sure fonts are loaded
    $(window).on('load', function () {


        //Waypoints

        
        animate();
    });

    $(document).ready(function($) {

        $('main').scroll(function(e) {
            if(e.target.scrollTop > 1) {
                $('.oxy-layout__header').addClass('oxy-layout__header--scrolled');
            }
            else {
                $('.oxy-layout__header').removeClass('oxy-layout__header--scrolled');
            }
        });

        // Parallax backgrounds
        $('.oxy-background-parallax').parallax();

    
   





 

        // Menu dropdown
        var $menuButton = $('.mdl-navigation__link--submenu');
        $($menuButton).click(function(e){
    e.preventDefault();
            $(this).toggleClass('is-open').siblings().removeClass('is-open');
          
        });
        
        
        $("#chef_applyBtn").click(function(e){
    e.preventDefault();
     toggleClass(document.getElementById('hc-left-menu'),'is-visible');
     $('.mdl-layout__obfuscator').removeClass( 'is-visible' );
                window.location.replace("#chef_application")
        
        });




        
        $('.hc-left-nav .mdl-navigation__link').click(function(e){
             toggleClass(document.getElementById('hc-left-menu'),'is-visible');
$('.mdl-layout__obfuscator').removeClass( 'is-visible' );
           
        });
        
               $('.right-options').click(function(e){
             toggleClass(document.getElementById('hc-product-cart'),'is-visible');
$('#side-cart-obfuscator').removeClass( 'is-visible' );
           
        });

        // Add to cart button animation

        // Add dismissable alert buttons
        $('body').on('click', '.oxy-alert--action', function() {
            $(this).parent().animate({opacity: 0}, 500, function(){
                $(this).remove();
            });
        });
    });
})(jQuery);
